export const environment = {
  production: true,

  //Endpoints
  apiLeagueServer: 'https://competeapi.fullswingapps.com/api',
  apiBaymanagerServer: 'https://launcher.fullswingapps.com/api/fs',
  apiBaymanagerPanel: 'https://baymanager.fullswingapps.com',
  apiAuthPanel: 'https://authapi.fullswingapps.com',
  apiAuthServer: 'https://authapi.fullswingapps.com/api',

  hostName: '',

  //Local strorage values
  navName: '_fs_nav_name',
  bayManagerUser: 'Bay-Manager-User',
  calendarState: '_fs_fc_state',

  langId: '_fs_lang_id',
  defaultLanguage: 'en',
  defaultFontFamily: 'DavisSans',

  breakPointScreen: {
    small: 576,
    Medium: 768,
    large: 992,
    extraLarge: 1200,
    xExtraLarge: 1400,
  },
};
